<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title>Dashboard</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">Dashboard</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-card>
                <ion-card-header class="ion-text-center">
                    <img src="assets/logo.png" alt="Dealer Incident Reporting"/>
                    <ion-card-title>Choose An Action</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                    <ion-button href="/tabs/incidents" expand="block">View Incidents</ion-button>
                    <ion-button href="/tabs/report" expand="block">Report Incident</ion-button>
                    <ion-button href="/logout" color="dark" expand="block">
                        <ion-icon :icon="logOut"/>
                        &nbsp;
                        Logout
                    </ion-button>
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script type="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonIcon } from '@ionic/vue';
import { logOut } from 'ionicons/icons';

export default {
    name: 'Dashboard',
    components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonIcon },
    setup(){
        return {
            logOut,
        };
    },
};
</script>
